// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MuiDataGrid-cell {
    display: flex;
    align-items: center;
  }

/* Apply the border to all column headers to their left side */
.MuiDataGrid-withBorderColor .MuiDataGrid-columnHeader {
  border-left: 1px solid lightgray;
}
`, "",{"version":3,"sources":["webpack://./src/global.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;EACrB;;AAEF,8DAA8D;AAC9D;EACE,gCAAgC;AAClC","sourcesContent":[".MuiDataGrid-cell {\n    display: flex;\n    align-items: center;\n  }\n\n/* Apply the border to all column headers to their left side */\n.MuiDataGrid-withBorderColor .MuiDataGrid-columnHeader {\n  border-left: 1px solid lightgray;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
