// project import
import jobwork from './jobwork';
import dashboard from './dashboard';
import utilities from './utilities';
import trims from './trims';
import Fabric from './Fabric';
import PurchaseOrder from './PurchaseOrder';
// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  items: (window.sessionStorage.getItem("module") !== null && window.sessionStorage.getItem("module") === 'fabric') ? [Fabric] : [dashboard, jobwork, utilities, trims, PurchaseOrder]
};

export default menuItems;
