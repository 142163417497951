import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { SKU_PERFORMANCE_MONTHLY_TRENDS_API, GET_SKU_PERFORMANCE_MONTHLY_TREND_DD_DATA, GET_LOCATION_DETAILS, API_LIMIT, DATA_FETCH_MSG } from 'pages/common/Constants';
import { requestAPI, getClientId } from 'pages/common/Utils';
import { populateColumns } from "store/common/populateColumns";
// Initial State
const initialState = {
    tableData: [],
    columnData: [],
    aggregationModel: {},
    locationData: [],
    location: '',
    btn: true,
    loading: false,
    isLoading: true,
    duration: '',
    durationData: [],
    colName1: '',
    colName2: '',
    colName3: '',
    openAlert: false,
    alertMessage: '',
    severity: 'success',
};

// Slice
const skuPerformanceMonthlySlice = createSlice({
    name: 'skuPerformanceMonthly',
    initialState,
    reducers: {
        setTableData: (state, action) => {
            state.tableData = action.payload;
        },
        setColumnData: (state, action) => {
            state.columnData = action.payload;
        },
        setAggregationModel: (state, action) => {
            state.aggregationModel = action.payload;
        },
        setLocationData: (state, action) => {
            state.locationData = action.payload;
        },
        setLocation: (state, action) => {
            state.location = action.payload;
        },
        setBtn: (state, action) => {
            state.btn = action.payload;
        },
        setLoading: (state, action) => {
            state.loading = action.payload;
        },
        setIsLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setDuration: (state, action) => {
            state.duration = action.payload;
        },
        setDurationData: (state, action) => {
            state.durationData = action.payload;
        },
        setOpenAlert: (state, action) => {
            state.openAlert = action.payload.open;
            state.alertMessage = action.payload.message;
            state.severity = action.payload.severity;
        },
    }
});


export const {
    tableData, setTableData,
    columnData, setColumnData,
    aggregationModel, setAggregationModel,
    locationData, setLocationData,
    location, setLocation,
    btn, setBtn,
    loading, setLoading,
    isLoading, setIsLoading,
    duration, setDuration,
    durationData, setDurationData,
    openAlert, setOpenAlert,
    error, setError
} = skuPerformanceMonthlySlice.actions;

export default skuPerformanceMonthlySlice.reducer;

// Async Thunks
// Async action to fetch duration data
export const fetchDurationData = () => async (dispatch) => {
    try {
        let arr = [];
        const response = await requestAPI(`${GET_SKU_PERFORMANCE_MONTHLY_TREND_DD_DATA}?cid=${getClientId()}`, 'GET', '', '');
        response.data.forEach(x => {
            if (x.id === 1) {
                const dur = x.monthYear;
                dispatch(setDuration(dur))
            }
            arr.push(x);
        });
        dispatch(setDurationData(arr))
    }
    catch (error) {
        dispatch(setError("Failed to fetch duration data"));
    }
};


// Async action to fetch location details
export const fetchLocationDetails = () => async (dispatch) => {

    try {
        const response = await requestAPI(`${GET_LOCATION_DETAILS}/${getClientId()}?reqtype=SKU_PERFORMANCE`, 'GET', '', '');

        const locationValue = response.data.find((loc) => loc.attribute === "All")?.attribute || response.data[0]?.attribute || "All";

        dispatch(setLocationData(response.data));
        dispatch(setLocation(locationValue));
    } catch (error) {
        dispatch(setError("Failed to fetch location details"));
    } finally {
        dispatch(setIsLoading(false));
    }
};

// Async action to fetch table data
export const fetchTableData = (duration, location) => async (dispatch) => {
    dispatch(setBtn(false));
    dispatch(setLoading(true));
    dispatch(setIsLoading(true));
    try {

        const response1 = await requestAPI(`${SKU_PERFORMANCE_MONTHLY_TRENDS_API}/${getClientId()}?loc=${location}&dur=${duration}&limit=${API_LIMIT}`, 'GET', '', '');
        const tableData1 = response1.data;

        dispatch(setTableData(tableData1));
        const { columns: c1, aggregation: agg1 } = populateColumns(tableData1);
        dispatch(setColumnData(c1))
        dispatch(setAggregationModel(agg1))
        dispatch(setIsLoading(false));
        dispatch(setLoading(false));

        const response2 = await requestAPI(`${SKU_PERFORMANCE_MONTHLY_TRENDS_API}/${getClientId()}?loc=${location}&dur=${duration}`, 'GET', '', '');
        const tableData2 = response2.data;
        dispatch(setTableData(tableData2));
        const { columns: c2, aggregation: agg2 } = populateColumns(response2.data);
        dispatch(setColumnData(c2))
        dispatch(setAggregationModel(agg2))
        dispatch(setBtn(true));
        dispatch(setLoading(false));
        dispatch(setIsLoading(false));
        dispatch(setOpenAlert({ open: true, message: DATA_FETCH_MSG, severity: 'success' }));
    } catch (error) {
        dispatch(setError("Failed to fetch table data"));
        dispatch(setLoading(false));
        dispatch(setIsLoading(false));
    }
};

