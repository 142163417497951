import React from "react";
import { useRef, useState } from 'react';
// material-ui
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Avatar from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import ListItemSecondaryAction from '@mui/material/ListItemSecondaryAction';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

// project import
import MainCard from 'components/MainCard';
import Transitions from 'components/@extended/Transitions';

// assets
import BellOutlined from '@ant-design/icons/BellOutlined';
import CheckCircleOutlined from '@ant-design/icons/CheckCircleOutlined';
import GiftOutlined from '@ant-design/icons/GiftOutlined';
import MessageOutlined from '@ant-design/icons/MessageOutlined';
import NotificationOutlined from '@ant-design/icons/NotificationOutlined';
import SettingOutlined from '@ant-design/icons/SettingOutlined';

import { requestAPI, getClientId } from "pages/common/Utils";
import { GET_NOTIFICATIONS, TRACK_NOTIFICATIONS } from "pages/common/Constants";
import { format } from 'date-fns';
import { useNavigate } from "react-router-dom";

// sx styles
const avatarSX = {
  width: 36,
  height: 36,
  fontSize: '1rem'
};

const actionSX = {
  mt: '6px',
  ml: 1,
  top: 'auto',
  right: 'auto',
  alignSelf: 'flex-start',

  transform: 'none'
};

// ==============================|| HEADER CONTENT - NOTIFICATION ||============================== //

export default function Notification() {
  const navigate = useNavigate();
  const theme = useTheme();
  const matchesXs = useMediaQuery(theme.breakpoints.down('md'));

  const anchorRef = useRef(null);
  const [read, setRead] = useState(0);
  const [open, setOpen] = useState(false);

  const [rows, setRows] = React.useState(() => []);
  const [notificationClicked, setNotificationClicked] = useState(new Set());

  React.useEffect(() => {
    requestAPI(GET_NOTIFICATIONS+"/"+getClientId(),'GET','','').then(resp => {
      const data = resp.data
      setRows(data)
      let count = 0;
      data.map(x => {
        if(x.newMessage)
          count = count + 1;
      })
      setRead(count)
    }).catch(err => console.log(err.response))
  }, []);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const iconBackColorOpen = 'grey.100';

  const redirect = (x) => {
    if(x.newMessage && !notificationClicked.has(x.id))
      addToTracker(x.id)
    let readValue = read
    if(readValue > 0 && (x.newMessage && !notificationClicked.has(x.id))){
      readValue = readValue - 1
      setRead(readValue)
    }
    notificationClicked.add(x.id)
    const path = window.location.pathname

    if(x.type === "Dynamic"){
      if(path.includes(x.redirectToPage)){
        navigate('/home/temp/?table='+x.redirectToTable);
      }else{
        navigate('/home/'+x.redirectToPage+'?table='+x.redirectToTable);
      }
    }
  };

  const addToTracker = (notificationId) => {
    requestAPI(TRACK_NOTIFICATIONS+"/"+getClientId()+"?id="+notificationId,'POST','','').then(resp => {
    }).catch(err => console.log(err.response))
  }

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <IconButton
        color="secondary"
        variant="light"
        sx={{ color: 'text.primary', bgcolor: open ? iconBackColorOpen : 'transparent' }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? 'profile-grow' : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Badge badgeContent={read} color="primary">
          <BellOutlined />
        </Badge>
      </IconButton>
      <Popper
        placement={matchesXs ? 'bottom' : 'bottom-end'}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{ modifiers: [{ name: 'offset', options: { offset: [matchesXs ? -5 : 0, 9] } }] }}
      >
        {({ TransitionProps }) => (
          <Transitions type="grow" position={matchesXs ? 'top' : 'top-right'} in={open} {...TransitionProps}>
            <Paper sx={{ boxShadow: theme.customShadows.z1, width: '100%', minWidth: 285, maxWidth: { xs: 285, md: 420 } }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard
                  title="Notifications"
                  elevation={0}
                  border={false}
                  content={false}
                  secondary={
                    <>
                      {read > 0 && (
                        <Tooltip title="Mark as all read" style={{ display: 'none' }}>
                          <IconButton color="success" size="small" onClick={() => setRead(0)}>
                            <CheckCircleOutlined style={{ fontSize: '1.15rem' }} />
                          </IconButton>
                        </Tooltip>
                      )}
                    </>
                  }
                >
                  {
                    rows.map(x =>{
                      let finalMessage = x.message
                      if(finalMessage.includes("{") && finalMessage.includes("}")){
                        const msgSplit1 = finalMessage.split("{")
                        const msgFirst = msgSplit1[0];
                        const msgSplit2 = msgSplit1[1].split("}")
                        const value = msgSplit2[0]
                        const msgLast = msgSplit2[1]
                        const element = <>{msgFirst}<span style={{ color: '#0096FF' }}><b>{value}</b></span>{msgLast}</>
                        finalMessage = element
                      }
                      return <List
                        component="nav"
                        sx={{
                          p: 0,
                          '& .MuiListItemButton-root': {
                            py: 0.5,
                            '&.Mui-selected': { bgcolor: 'grey.50', color: 'text.primary' },
                            '& .MuiAvatar-root': avatarSX,
                            '& .MuiListItemSecondaryAction-root': { ...actionSX, position: 'relative' }
                          }
                        }}
                      >
                        <ListItemButton style={{backgroundColor : (x.newMessage && !notificationClicked.has(x.id)) ? "#f4f4ec" : ''}}>
                          <ListItemAvatar>
                            <Avatar sx={{ color: 'primary.main', bgcolor: 'primary.lighter' }}>
                              {(x.newMessage && !notificationClicked.has(x.id)) ? <NotificationOutlined /> : <CheckCircleOutlined style={{ fontSize: '1.15rem' }} />}
                            </Avatar>
                          </ListItemAvatar>
                          <ListItemText onClick={() => redirect(x)}
                            primary={
                              <Typography variant="h6">
                                <Typography component="span" variant={(x.newMessage && !notificationClicked.has(x.id)) ? "subtitle1" : "subtitle2"}>
                                  {finalMessage}
                                </Typography>{' '}
                              </Typography>
                            }
                            secondary={format(new Date(), 'dd - MMM').toUpperCase()}
                          />
                          <ListItemSecondaryAction>
                            <Typography variant="caption" noWrap>
                              
                            </Typography>
                          </ListItemSecondaryAction>
                        </ListItemButton>
                        <Divider />
                      </List>
                    })
                  }
                  
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
}