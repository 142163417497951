// assets
import { DashboardOutlined,StockOutlined,ClockCircleOutlined,PlusSquareOutlined,OrderedListOutlined } from '@ant-design/icons';

// icons
const icons = {
  DashboardOutlined,
  StockOutlined,
  ClockCircleOutlined,
  PlusSquareOutlined,
  OrderedListOutlined
};

const client = window.sessionStorage.getItem("client")
const level = window.sessionStorage.getItem("level")

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: 'group-dashboard',
  title: 'Inventory',
  type: level === 'ADMIN' ? 'group' : 'none',
  children: [
    {
      id: client === 'demo' ? 'dashboard' : 'instockpercentage',
      title: client === 'demo' ? 'Dashboard' : 'Instock Percentage',
      type: 'item',
      url: '/home/InstockPercentage',
      icon: icons.StockOutlined,
      breadcrumbs: true
    },
    {
      id: 'inventoryhealth',
      title: 'Inventory Health',
      type: client === 'knyamed' ? 'none' : 'item',
      url: client === 'demo' ? '/home/InventoryHealthDemo' : '/home/InventoryHealth',
      icon: icons.PlusSquareOutlined,
      breadcrumbs: true
    },
    {
      id: 'skuperformance',
      title: 'SKU Performance',
      type: 'item',
      url: '/home/SkuPerformance',
      icon: icons.DashboardOutlined,
      breadcrumbs: true
    },
    {
      id: 'replenishmentplanner',
      title: client === 'demo' ? 'SKU Ordering' : 'Replenishment Planner',
      type: 'item',
      url: '/home/ReplenishmentPlanner',
      icon: icons.ClockCircleOutlined,
      breadcrumbs: true
    },{
      id: 'margin',
      title: 'Margin',
      type: client === 'demo' ? 'item' : 'none',
      url: '/home/mgn',
      icon: icons.OrderedListOutlined,
      breadcrumbs: true
    }
  ]
};

export default dashboard;
