import { lazy } from 'react';

// project import
import Loadable from 'components/Loadable';
import MainLayout from 'layout/MainLayout';

const Temp = Loadable(lazy(() => import('pages/common/Temp')));
// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('pages/dashboard')));

const FabricPOForm = Loadable(lazy(() => import('pages/fabric/FabricPOForm')));
const FabricDashboard = Loadable(lazy(() => import('pages/fabric/FabricDashboard')));
const FabricProcessing = Loadable(lazy(() => import('pages/fabric/FabricProcessing')));
const FabricProcessingVendor = Loadable(lazy(() => import('pages/fabric/FabricProcessingVendor')));
const FabricVendorMaster = Loadable(lazy(() => import('pages/fabric/FabricVendorMaster')));
const FabricMaster = Loadable(lazy(() => import('pages/fabric/FabricMaster')));
const FabricBOM = Loadable(lazy(() => import('pages/fabric/FabricBOM')));
const FabricDisplayPOPdf = Loadable(lazy(() => import('pages/fabric/FabricDisplayPOPdf')));
const FabricInventoryDashboard = Loadable(lazy(() => import('pages/fabric/FabricInventoryDashboard')));
const FabricInventoryOut = Loadable(lazy(() => import('pages/fabric/FabricInventoryOut')));


// render - sample page
//const SamplePage = Loadable(lazy(() => import('pages/extra-pages/SamplePage')));

// render - utilities
const Typography = Loadable(lazy(() => import('pages/components-overview/Typography')));
const Color = Loadable(lazy(() => import('pages/components-overview/Color')));
const Shadow = Loadable(lazy(() => import('pages/components-overview/Shadow')));
const AntIcons = Loadable(lazy(() => import('pages/components-overview/AntIcons')));

const InventoryHealth = Loadable(lazy(() => import('pages/inventory/InventoryHealth')));

const InventoryHealthDemo = Loadable(lazy(() => import('pages/inventory/demo/InventoryHealthDemo')));


const SkuPerformance = Loadable(lazy(() => import('pages/inventory/SkuPerformance')));

const ReplenishmentPlanner = Loadable(lazy(() => import('pages/inventory/ReplenishmentPlanner')));

const PurchaseOrdersDashboard = Loadable(lazy(() => import('pages/inventory/PurchaseOrdersDashboard')));
const PurchaseOrdersDashboardDemo = Loadable(lazy(() => import('pages/inventory/demo/PurchaseOrdersDashboardDemo')));
const PurchaseOrdersDetails = Loadable(lazy(() => import('pages/inventory/PurchaseOrdersDetails')));
const PurchaseOrdersCart = Loadable(lazy(() => import('pages/inventory/PurchaseOrdersCart')));
const PurchaseOrdersCartDemo = Loadable(lazy(() => import('pages/inventory/demo/PurchaseOrdersCartDemo')));
const PurchaseOrdersPdf = Loadable(lazy(() => import('pages/inventory/PurchaseOrdersPdf')));
const PurchaseOrdersCartPdf = Loadable(lazy(() => import('pages/inventory/PurchaseOrdersCartPdf')));
const PurchaseOrdersDispatchPdf = Loadable(lazy(() => import('pages/inventory/PurchaseOrdersDispatchPdf')));


const PurchaseOrdersBagsPdf = Loadable(lazy(() => import('pages/inventory/PurchaseOrdersBagsPdf')));
const PurchaseOrdersDispatchDetails = Loadable(lazy(() => import('pages/inventory/PurchaseOrdersDispatchDetails')));

const ForecastPlanner = Loadable(lazy(() => import('pages/inventory/demo/ForecastPlannerDemo')));

const DemoPdfGenerator = Loadable(lazy(() => import('pages/inventory/demo/DemoPdfGenerator')));

const Margin = Loadable(lazy(() => import('pages/inventory/demo/MarginDemo')));

const SKUMaster = Loadable(lazy(() => import('pages/utilities/SKUMaster')));

const FutureInvLevel = Loadable(lazy(() => import('pages/utilities/FutureInvLevel')));

const VendorPerformance = Loadable(lazy(() => import('pages/utilities/VendorPerformance')));

const MOQ = Loadable(lazy(() => import('pages/utilities/demo/MOQDemo')));

const Opportunity = Loadable(lazy(() => import('pages/utilities/Opportunity')));

const CreateJobWork = Loadable(lazy(() => import('pages/jobwork/CreateJobWork')));

const UpdateJobWork = Loadable(lazy(() => import('pages/jobwork/UpdateJobWork')));

const TrackJobWork = Loadable(lazy(() => import('pages/jobwork/TrackJobWork')));

const EditJobWorkDetails = Loadable(lazy(() => import('pages/jobwork/EditJobWorkDetails')));

const DisplayPOPdf = Loadable(lazy(() => import('pages/jobwork/DisplayPOPdf')));

const TrimCreate = Loadable(lazy(() => import('pages/trims/TrimCreate')));

const TrimUpdate = Loadable(lazy(() => import('pages/trims/TrimUpdate')));

const TrimTrack = Loadable(lazy(() => import('pages/trims/TrimTrack')));

const DisplayTrimsPOPdf = Loadable(lazy(() => import('pages/trims/DisplayTrimsPOPdf')));

const GrossMargin = Loadable(lazy(() => import('pages/demo/GrossMargin')));

const MarginTrend = Loadable(lazy(() => import('pages/demo/MarginTrend')));

const NewSKUPerformance = Loadable(lazy(() => import('pages/demo/NewSKUPerformance')));

const SalesTrend = Loadable(lazy(() => import('pages/demo/SalesTrend')));

const UserActivation = Loadable(lazy(() => import('pages/authentication/UserActivation')));

const Test = Loadable(lazy(() => import('pages/demo/Test')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/home',
  element: <MainLayout />,
  children: [
    {
      path: 'color',
      element: <Color />
    },
    {
      path: 'dashboard',
      children: [
        {
          path: 'default',
          element: <DashboardDefault />
        }
      ]
    },
    {
      path: 'activate/user',
      element: <UserActivation />
    },
    {
      path: 'test',
      element: <Test />
    },
    {
      path: 'fabric',
      element: <FabricPOForm />
    },
    {
      path: 'fabric/dashboard',
      element: <FabricDashboard />
    },
    {
      path: 'fabric/processing',
      element: <FabricProcessing />
    },
    {
      path: 'fabric/bom',
      element: <FabricBOM />
    },
    {
      path: 'fabric/master',
      element: <FabricMaster />
    },
    {
      path: 'fabric/processingvendor',
      element: <FabricProcessingVendor />
    },
    {
      path: 'fabric/vendormaster',
      element: <FabricVendorMaster />
    },
    {
      path: 'fabric/fbdpopdf',
      element: <FabricDisplayPOPdf />
    },
    {
      path: 'fabric/inventorydashboard',
      element: <FabricInventoryDashboard />
    },
    {
      path: 'fabric/inventoryout',
      element: <FabricInventoryOut />
    },
    {
      path: 'shadow',
      element: <Shadow />
    },
    {
      path: 'typography',
      element: <Typography />
    },
    {
      path: 'icons/ant',
      element: <AntIcons />
    },
    {
      path: 'InstockPercentage',
      element: <DashboardDefault />
    },
    {
      path: 'temp',
      element: <Temp />
    },
    {
      path: 'SkuPerformance',
      element: <SkuPerformance />
    },
    {
      path: 'ReplenishmentPlanner',
      element: <ReplenishmentPlanner />
    },
    {
      path: 'skim',
      element: <SKUMaster />
    },
    {
      path: 'fil',
      element: <FutureInvLevel />
    },
    {
      path: 'vp',
      element: <VendorPerformance />
    },
    {
      path: 'moq',
      element: <MOQ />
    },
    {
      path: 'oppo',
      element: <Opportunity />
    },
    {
      path: 'ipo',
      element: <CreateJobWork />
    },
    {
      path: 'igrn',
      element: <UpdateJobWork />
    },
    {
      path: 'idash',
      element: <TrackJobWork />
    },
    {
      path: 'EditJobWorkDetails',
      element: <EditJobWorkDetails />
    },
    {
      path: 'dpopdf',
      element: <DisplayPOPdf />
    },
    {
      path: 'tcr',
      element: <TrimCreate />
    },
    {
      path: 'tup',
      element: <TrimUpdate />
    },
    {
      path: 'ttr',
      element: <TrimTrack />
    },
    {
      path: 'dtrimpopdf',
      element: <DisplayTrimsPOPdf />
    },
    {
      path: 'gm',
      element: <GrossMargin />
    },
    {
      path: 'mt',
      element: <MarginTrend />
    },
    {
      path: 'nsp',
      element: <NewSKUPerformance />
    },
    {
      path: 'st',
      element: <SalesTrend />
    },
    {
      path: 'InventoryHealth',
      element: <InventoryHealth />
    },
    {
      path: 'InventoryHealthDemo',
      element: <InventoryHealthDemo />
    },
    {
      path: 'plg',
      element: <ForecastPlanner />
    },
    {
      path: 'dpdf',
      element: <DemoPdfGenerator />
    },
    {
      path: 'mgn',
      element: <Margin />
    },
    {
      path: 'Purchase/Orders/Dashboard',
      element: <PurchaseOrdersDashboard />
    },
    {
      path: 'Purchase/Orders/Details',
      element: <PurchaseOrdersDetails />
    },
    {
      path: 'Purchase/Orders/Cart',
      element: <PurchaseOrdersCart />
    },
    {
      path: 'Purchase/Orders/Pdf',
      element: <PurchaseOrdersPdf />
    },
    {
      path: 'Purchase/Orders/Cart/Pdf',
      element: <PurchaseOrdersCartPdf />
    },
    {
      path: 'Purchase/Orders/Dispatch/Pdf',
      element: <PurchaseOrdersDispatchPdf />
    },
    {
      path: 'Purchase/Orders/Cart/Demo',
      element: <PurchaseOrdersCartDemo />
    },
    {
      path: 'Purchase/Orders/Dashboard/Demo',
      element: <PurchaseOrdersDashboardDemo />
    },
    {
      path: 'Purchase/Orders/Bags/Pdf',
      element: <PurchaseOrdersBagsPdf />
    },
    {
      path: 'Purchase/Orders/Dispatch/Details',
      element: <PurchaseOrdersDispatchDetails />
    },
  ]
};

export default MainRoutes;
