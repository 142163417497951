// src/redux/slices/ReplenishmentPlannerCart.js
import { createSlice } from '@reduxjs/toolkit';
import { API_LIMIT, DATA_FETCH_MSG, GET_LOCATION_DETAILS, REPLENISH_PLANNER_API } from 'pages/common/Constants';
import { requestAPI, getClientId } from "pages/common/Utils";
import { populateColumns } from 'store/common/replenishmentPopulate';

const initialState = {
    location: '',
    locationData: [],
    locationValue: '',
    rows: [],
    columnData: [],
    aggregationModel: {},
    loading: false,
    isLoading: false,
    goBtn: false,
    openAlert: false,
    alertMessage: '',
    severity: 'success',
    daysInvPlanned: 30,
    pastSalesDRR: 30,
};

const ReplenishmentPlannerCart = createSlice({
    name: 'replenishmentPlannerCart',
    initialState,
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setError(state, action) {
            state.error = action.payload;
        },
        setGoBtn(state, action) {
            state.goBtn = action.payload;
        },
        setLocation(state, action) {
            state.location = action.payload;
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
        setLocationData(state, action) {
            state.locationData = action.payload;
        },
        setRows(state, action) {
            state.rows = action.payload;
        },
        setColumnData(state, action) {
            state.columnData = action.payload;
        },
        setAggregationModel(state, action) {
            state.aggregationModel = action.payload;
          },
        setDaysInvPlanned(state, action) {
            state.daysInvPlanned = action.payload;
        },
        setPastSalesDRR(state, action) {
            state.pastSalesDRR = action.payload;
        },
        setOpenAlert(state, action) {
            state.openAlert = action.payload.openAlert;
            state.alertMessage = action.payload.alertMessage;
            state.severity = action.payload.severity;
        },
    },
});

export const {
    setLoading,
    setIsLoading,
    setGoBtn,
    location, setLocation,
    setLocationData,
    setRows,
    setOpenAlert,
    daysInvPlanned, setDaysInvPlanned,
    pastSalesDRR, setPastSalesDRR,
    setError,
    columnData,setColumnData,
    aggregationModel, setAggregationModel,
} = ReplenishmentPlannerCart.actions;

export default ReplenishmentPlannerCart.reducer;


export const fetchLocationDetails = (locationDataActive) => async (dispatch) => {
    dispatch(setGoBtn(true))
    try {
        // Get the existing locationDataActive from the state
        const locationValue = locationDataActive.find((x) => x.attribute === "All")?.attribute || locationDataActive[0]?.attribute;
        dispatch(setLocationData([...locationDataActive]));
        dispatch(setLocation(locationValue));

        dispatch(setGoBtn(false))

    } catch (error) {
        dispatch(setError("Failed to fetch location details"));
    }
};



export const fetchReplenishmentPlannerData = (pastSalesDRR, daysInvPlanned, location) => async (dispatch) => {
    dispatch(setGoBtn(true));
    dispatch(setIsLoading(true));
    dispatch(setLoading(true));

    let reqURL = REPLENISH_PLANNER_API + "/" + getClientId() + "?days=" + daysInvPlanned + "&psalesdrr=" + pastSalesDRR + "&loc=" + location
    try {

        const response1 = await requestAPI(reqURL + "&limit=" + API_LIMIT, 'GET', '', '')
        dispatch(setRows(response1.data));
        const { columns: c1, aggregation: agg1 } = populateColumns(response1.data);
        dispatch(setColumnData(c1));
        dispatch(setAggregationModel(agg1));
        dispatch(setIsLoading(false));
        dispatch(setLoading(false));

        const response2 = await requestAPI(reqURL, 'GET', '', '')
        dispatch(setRows(response2.data));
        const { columns: c2, aggregation: agg2 } = populateColumns(response2.data);
        dispatch(setColumnData(c2));
        dispatch(setAggregationModel(agg2));
        dispatch(setGoBtn(false));
        dispatch(setOpenAlert({
            openAlert: true,
            alertMessage: DATA_FETCH_MSG,
            severity: "success"
        }));

    } catch (error) {
        console.log("Failed to fetch Replenishment Planner data : ", error);
        dispatch(setError("Failed to fetch Replenishment Planner data"));
    }
};