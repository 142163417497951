/*** run with local-backend ***/
// export const PROTOCOL = "http"; 
// export const IP = "localhost"; //test.custom-erp.net   //34.235.200.138
// export const PORT = ":8080";

//run with live-backend
export const PROTOCOL = "https"; 
export const IP = "test.custom-erp.net"; //test.custom-erp.net   //34.235.200.138
export const PORT = "";

export const PREFIX = "/newinventory";
export const GS_REDIRECT_URI = PROTOCOL+"://inventory.custom-erp.net";
//export const GS_REDIRECT_URI = PROTOCOL+"://localhost:3000";
export const API_LIMIT ="50"
export const DATA_FETCH_MSG = "All data fetched successfully"
export const LOGOUT_LINK = "https://inventory.custom-erp.net";
export const TOKEN_API = PROTOCOL+"://"+IP+""+PORT+"/auth/token";
export const TOKEN_VALIDATE_API = PROTOCOL+"://"+IP+""+PORT+"/auth/validate";
export const USER_ACTIVATION_API = PROTOCOL+"://"+IP+""+PORT+"/auth/activate/user";
export const USER_DETAILS_API = PROTOCOL+"://"+IP+""+PORT+"/auth/user/details";

export const DOWNLOAD_SKU_LIST_DB3 = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/download/sku/list";


export const USER_REGISTER_API = PROTOCOL+"://"+IP+""+PORT+"/auth/register";

export const SAVE_REPLENISH_DATA = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/save/replenish";
export const SAVE_MOQ_DATA = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/save/moq";
export const GET_MOQ_DATA = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/get/moq";

export const DATA_SYNC_DETAILS = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/data/sync/details";
export const CLOSE_DISPATCH_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/close/dispatch";

export const SAVE_CSV_PURCHASE_ORDERS_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/po/save/csv";
export const GENERATE_PO_NUM  = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/generate/purchase/order/number";
export const PURCHASE_ORDERS_DETAILS_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/purchase/orders/details";
export const PURCHASE_ORDERS_BAG_DETAILS_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/purchase/orders/bag/details";
export const PURCHASE_ORDERS_DASHBOARD_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/purchase/orders/dashboard";
export const PURCHASE_ORDERS_DISPATCH_DASHBOARD_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/purchase/orders/dispatch/dashboard";
export const PURCHASE_ORDERS_DISPATCH_DETAILS_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/purchase/orders/dispatch/details";
export const SAVE_PURCHASE_ORDERS_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/po/save";
export const SAVE_DISPATCH_PURCHASE_ORDERS_API = PROTOCOL + "://" + IP + "" + PORT + PREFIX + "/dispatch/po/save";

//export const ALAMODE_REPLENISHMENT_DATA_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/alamode/replenishment/data";
export const ADD_TO_CART_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/add/cart";
export const FETCH_CART_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/fetch/cart";
export const CLEAR_CART_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/clear/cart";
export const GENERATE_PO_FROM_CART_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/cart/generate/po";

export const INVENTORY_HEALTH_SUMMARY_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/inv/health/summary";
export const EXCESS_INVENTORY_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/excess/inventory";


export const GET_GROWTH_MULTIPLIER_DATA = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/growth/multiplier/data";

export const GET_NOTIFICATIONS = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/notifications";
export const TRACK_NOTIFICATIONS = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/notifications/tracker";

export const SKU_MASTER_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/sku/master";
export const SKU_MASTER_UPLOAD_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/bulk/upload/sku/master";

export const SKU_OPEN_PO_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/sku/open/po";
export const SKU_OPEN_PO_UPLOAD_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/bulk/upload/sku/open/po";

export const GET_ATTRIBUTES_VARIANTS_DETAILS = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/get/attributes/variants";
export const GET_LOCATION_DETAILS = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/get/location";

export const SKU_VENDOR_MAPPING_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/sku/vendor/mapping";
export const SKU_VENDOR_MAPPING_UPLOAD_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/bulk/upload/sku/vendor/mapping";

export const PREDICTION_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/predictions";
export const PREDICTION_API_NEW = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/predictions";
export const REPLENISH_API_BMR = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/replenish/bmr";
export const REPLENISH_API_CART = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/replenish";

export const SKU_PERFORMANCE_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/sku/performance";
export const SKU_PERFORMANCE_DAILY_TRENDS_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/sku/performance/daily/trends";
export const SKU_PERFORMANCE_WEEKLY_TRENDS_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/sku/performance/weekly/trends";
export const SKU_PERFORMANCE_MONTHLY_TRENDS_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/sku/performance/monthly/trends";

export const REPLENISH_PLANNER_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/replenishment/planner";
export const TEST_REPLENISH_PLANNER_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/test/replenishment/planner";
export const REPLENISH_PLANNER_OLD_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/replenishment/planner/old";

export const DASHBOARD_SALES_UNIT_VALUES_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/dashboard/sales/unit/values";

export const GET_SKU_PERFORMANCE_MONTHLY_TREND_DD_DATA = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/skuperformance/monthly/trends/dd/data";
export const SKU_PERFORMANCE_MONTHLY_DATA_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/skuperformance/monthly/trends";

export const GET_SKU_PERFORMANCE_DAILY_TREND_DD_DATA = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/sku/performance/daily/trend/channels";
export const SKU_PERFORMANCE_DAILY_DATA_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/sku/performance/daily/trend/data";

export const SKU_PERFORMANCE_WEEKLY_DATA_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/sku/performance/weekly/trend/data";


export const INV_PLANNER_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/predictions";

export const REPLENISH_API_FBP = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/replenish/fbp";
export const REPLENISH_API_SALTY = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/replenish/salty";
export const REPLENISH_API_MIRGGIOLIFE = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/replenish/mirggiolife";
export const REPLENISH_API_RUBANS = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/replenish/rubans";

export const REPLENISH_API_KNYAMED = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/replenish/knyamed";
export const VENDOR_DASHBOARD_MONTH_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/get/vendor/dashboard/month";
export const VENDOR_DASHBOARD_DATA_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/get/vendor/dashboard/data";

export const INSTOCK_PERCENTAGE_GRAPH_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/get/instock/percentage/graph/data";

export const BESTSELLERS_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/bestsellers";

export const PRINT_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/printed";

export const OPPORTUNITY_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/opportunities";

export const GS_WRITE_API = PROTOCOL+"://"+IP+""+PORT+"/util/gs/write";

export const BROKEN_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/broken";

export const INVENTORY_TOTAL_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/total";

export const INVENTORY_TOTAL_ITEMS_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/total/items";

export const INVENTORY_SKU_REPLENISH_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/sku/replenish";

export const INVENTORY_WEEKLY_SALES_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/weekly/sales";

export const INVENTORY_WEEKLY_SALES_SKUS_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/weekly/sales/skus";
export const INVENTORY_PO_PAGE_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/po/page/skus";
export const PO_S3_IMG = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/po/s3/img";
export const SKU_ITEM_IMG_MASTER = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/sku/item/img/master";
export const GET_INSTOCK_PERCENTAGE = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/instock/percentage";
export const GET_DOC_BUCKET_PIVOT_DATA = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/doc/bucket/pivot";

export const SKU_DISPLAY_PO_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/display/po";
export const SKU_COLORS_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/sku/colors";
export const SKU_PO_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/sku/po";

export const TRIM_CREATE_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/trim/create";
export const TRIM_GENERATE_PO_NUM = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/generate/trim/number";
export const TRIM_CHECK_PO_NUM = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/check/trim/number";
export const TRIM_PO_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/trim/po";
export const TRIM_PODATA_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/trim/podata";
export const TRIM_UPDATE_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/trim/grn";
export const TRIM_DASHBOARD_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/trim/dashboard";
export const TRIM_VENDOR_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/trim/vendors";
export const TRIM_PO_PAGE_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/trim/po/page/skus";
export const TRIM_INVOICE_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/trim/invoice";
export const SKU_DISPLAY_TRIM_PO_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/trim/display/po";

export const KNYA_PO_DATA = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/knya/po/data";
export const KNYA_UPDATE_PO = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/knya/update/po";

export const SKU_GRN_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/sku/grn";
export const SKU_PODATA_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/sku/podata";
export const SKU_TEST_REPORT_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/sku/test/report";
export const SKU_INVOICE_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/sku/invoice";
export const SKU_IMAGE_UPLOAD_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/upload/po/sku/image";
export const S3_API_PATH = "https://custom-erp.s3.amazonaws.com/";
export const S3_PO_IMG_PATH = "https://custom-erp.s3.amazonaws.com/KNYA/PO_IMAGES"
export const SKU_GENERATE_PO_NUM = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/generate/po/number";
export const SKU_CHECK_PO_NUM = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/check/po/number";
//export const REQUEST_INVENTORY_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/request/inv";
//export const REQUEST_INVENTORY_UPLOAD_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/upload/request/inv";
//export const MOCKUP_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/mockup";
//export const MOCKUP_UPLOAD_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/upload/mockup";
//export const FABRIC_SKU_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/sku/fabric/data";
//export const FABRIC_SKU_MAPPED = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/sku/fabric/mapped";
export const FUTURE_INV_LEVEL_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/sku/future/inv/level";
export const DEMO_INV_PLANNER_DATA_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/sku/demo/inv/planner/data";
export const DEMO_NEW_SKU_PERFORMANCE_DATA_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/sku/demo/new/sku/performance/data";
export const DEMO_PLANNING_DATA_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/sku/demo/planning/data";
export const DEMO_REPLENISH_DATA_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/sku/demo/replenishment/data";
export const DEMO_REORDER_NOW_DATA_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/sku/demo/reorder/now/data";
export const DEMO_MONTHLY_TRENDS_DATA_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/sku/demo/monthly/trends/data";
export const DEMO_DAILY_SALES_DATA_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/sku/demo/dailysales/data";
export const DEMO_DAILY_SALE_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/demo/daily/sales/data";
export const DEMO_MONTHLY_SALE_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/demo/monthly/sales/data";
export const DEMO_GROSS_MARGIN_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/demo/gross/margin/data";
export const DEMO_RETURNS_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/demo/returns/data";


export const VENDOR_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/sku/vendors";
export const PO_SKU_API = PROTOCOL+"://"+IP+""+PORT+"/po/sku";
export const GRN_SKU_API = PROTOCOL+"://"+IP+""+PORT+"/grn/sku";




export const PO_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"po";

export const FABRIC_COLOR_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"fabric";
export const FABRIC_TYPE_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"fabric/type";
export const PO_FABRIC_DETAILS_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"fabric/details/po";

export const GRN_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"grn";

export const QC_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"qc";

export const DASHBOARD_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/dashboard";

export const HELP_MENUS =  PROTOCOL+"://"+IP+""+PORT+PREFIX+"/help/menus"









export const VENDOR_PROCESSING_UPLOAD_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"vendor/processing/upload";






export const FABRIC_OUT_API = PROTOCOL+"://"+IP+""+PORT+PREFIX+"fabric/out";









/* ----------------------------------------------- Fabric PO --------------------------------------------------- */
export const FB_VENDOR_API = PROTOCOL+"://"+IP+""+PORT+"/vendor";
export const FB_FABRIC_API = PROTOCOL+"://"+IP+""+PORT+"/fabric";
export const FB_PO_API = PROTOCOL+"://"+IP+""+PORT+"/po";
export const FB_DASHBOARD_API = PROTOCOL+"://"+IP+""+PORT+"/util/dashboard";

export const PROCESSING_API = PROTOCOL+"://"+IP+""+PORT+"/processing";
export const VENDOR_PROCESSING_API = PROTOCOL+"://"+IP+""+PORT+"/vendor/processing";
export const FB_VENDOR_UPLOAD_API = PROTOCOL+"://"+IP+""+PORT+"/vendor/upload";

export const FABRIC_API = PROTOCOL+"://"+IP+""+PORT+"/fabric";
export const FABRIC_UPLOAD_API = PROTOCOL+"://"+IP+""+PORT+"/fabric/upload";
export const FABRIC_IMAGE_UPLOAD_API = PROTOCOL+"://"+IP+""+PORT+"/fabric/image/upload";
export const FABRIC_IMAGE_S3_API = "https://custom-erp.s3.amazonaws.com/";

export const FB_BOM_API = PROTOCOL+"://"+IP+""+PORT+"/fabric/bom";
export const FB_BOM_UPLOAD_API = PROTOCOL+"://"+IP+""+PORT+"/fabric/bom/upload";
export const FB_DISPLAY_PO_API = PROTOCOL+"://"+IP+""+PORT+"/po/display";
export const FB_INV_DASHBOARD_API = PROTOCOL+"://"+IP+""+PORT+"/util/inv/dashboard";
export const FB_GENERATE_INTERNAL_PO_REF = PROTOCOL+"://"+IP+""+PORT+"/po/generate/po/number";
export const FB_SKU_INVOICE_API = PROTOCOL+"://"+IP+""+PORT+"/po/sku/invoice";



/* ----------------------------------------------- User Activity Tracker --------------------------------------------------- */
export const USER_TRACKER = PROTOCOL+"://"+IP+""+PORT+PREFIX+"/user/tracker";