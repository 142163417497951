import { createSlice } from "@reduxjs/toolkit";
import {
  API_LIMIT,
  DATA_FETCH_MSG,
  GET_LOCATION_DETAILS,
  SKU_PERFORMANCE_WEEKLY_TRENDS_API,
} from "pages/common/Constants";
import { requestAPI, getClientId } from "pages/common/Utils";
import { populateColumns } from "store/common/populateColumns";


// Initial state for SKU performance
const initialState = {
  tableData: [],
  columnData: [],
  locationData: [],
  selectedLocation: "",
  aggregationModel: {},
  openAlert: false,
  alertMessage: '',
  severity: 'success',
  btn: true,
  isLoading: true,
  loading: false,
  error: null,
};

// Create the SKU Performance Daily slice
const skuPerformanceWeeklySlice = createSlice({
  name: "skuPerformanceWeekly",
  initialState,
  reducers: {
    setLoading(state, action) {
      state.loading = action.payload;
    },
    setIsLoading(state, action) {
      state.isLoading = action.payload;
    },
    setTableData(state, action) {
      state.tableData = action.payload;
      state.loading = false;
    },
    setColumnData(state, action) {
      state.columnData = action.payload;
    },
    setLocationData(state, action) {
      state.locationData = action.payload;
    },
    setSelectedLocation(state, action) {
      state.selectedLocation = action.payload;
    },
    setBtn(state, action) {
      state.btn = action.payload;
    },
    setAggregationModel(state, action) {
      state.aggregationModel = action.payload;
    },
    setOpenAlert(state, action) {
      state.openAlert = action.payload;
    },
    setAlertMessage(state, action) {
      state.alertMessage = action.payload;
    },
    setSeverity(state, action) {
      state.severity = action.payload;
    },
    setError(state, action) {
      state.error = action.payload;
      state.loading = false;
    },
    clearSkuPerformanceData(state) {
      state.tableData = [];
      state.locationData = [];
      state.selectedLocation = '';
    },
  },
});

// Export actions and reducer
export const {
  btn, setBtn,
  error, setError,
  loading, setLoading,
  isLoading, setIsLoading,
  tableData, setTableData,
  columnData, setColumnData,
  locationData, setLocationData,
  selectedLocation, setSelectedLocation,
  aggregationModel, setAggregationModel,
  openAlert, setOpenAlert,
  alertMessage, setAlertMessage,
  severity, setSeverity,
  clearSkuPerformanceData,
} = skuPerformanceWeeklySlice.actions;

export default skuPerformanceWeeklySlice.reducer;


// Async action to fetch location details
export const fetchLocationDetails = () => async (dispatch) => {
  try {
    const response = await requestAPI(GET_LOCATION_DETAILS + '/' + getClientId() + "?reqtype=SKU_PERFORMANCE", 'GET', '', '')
    const locationValue = response.data.find((x) => x.attribute === "All")?.attribute || response.data[0]?.attribute || "All";
    dispatch(setLocationData(response.data))
    dispatch(setSelectedLocation(locationValue))
    dispatch(fetchSkuPerformanceData(locationValue))

  } catch (error) {
    dispatch(setError("Failed to fetch location details"));
  }
};

// Async action to fetch SKU performance data
export const fetchSkuPerformanceData = (locationValue) => async (dispatch) => {
  dispatch(setBtn(false));
  dispatch(setIsLoading(true))
  try {
    const response1 = await requestAPI(SKU_PERFORMANCE_WEEKLY_TRENDS_API + "/" + getClientId() + "?loc=" + locationValue + "&limit=" + API_LIMIT, 'GET', '', '')
    dispatch(setTableData(response1.data));
    const { columns: c1, aggregation: agg1 } = populateColumns(response1.data);
    dispatch(setColumnData(c1))
    dispatch(setAggregationModel(agg1))
    dispatch(setIsLoading(false))



    const response2 = await requestAPI(SKU_PERFORMANCE_WEEKLY_TRENDS_API + "/" + getClientId() + "?loc=" + locationValue, 'GET', '', '')
    dispatch(setTableData(response2.data));
    const { columns: c2, aggregation: agg2 } = populateColumns(response2.data);
    dispatch(setColumnData(c2))
    dispatch(setAggregationModel(agg2))
    dispatch(setBtn(true));
    dispatch(setOpenAlert(true));
    dispatch(setAlertMessage(DATA_FETCH_MSG));
    dispatch(setSeverity("success"));
    dispatch(setLoading(false));

  } catch (error) {
    console.log("Failed to fetch SKU performance weekly data : ", error)
    dispatch(setError("Failed to fetch SKU performance weekly data"));
  }
};





