import { CardMedia } from '@mui/material';

export const populateColumns = (tableData) => {
  const imageColumn = {
    field: 'Image', headerName: 'Image', width: 160, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center',
    renderCell: ({ row }) => {
      return <CardMedia component="img" image={row.Image} style={{ borderRadius: '10%' }} />;
    }
  };

  const columns = [];
  const aggregation = {};

  tableData.forEach(x => {
    if (x.id > 1) return;


    for (var key in x) {
      const value = x[key];
      if (!isNaN(value)) {
        aggregation[key] = 'sum';
      }

      if (key === 'id') continue;
      if (key === 'Image') columns.push(imageColumn);
      else if (key === 'Description') columns.push({ field: key, type: !isNaN(value) ? 'number' : 'string', headerName: key, width: 320, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center' });
      else if (key.endsWith("ID") || key.endsWith("Id") || key.endsWith("id")) {
        columns.push({
          field: key,
          type: "string",
          headerName: key,
          width: 160,
          headerClassName: "super-app-theme--header",
          headerAlign: "center",
          align: "center",
        });
      } else {
        columns.push({ field: key, type: !isNaN(value) ? 'number' : 'string', headerName: key, width: 160, headerClassName: 'super-app-theme--header', headerAlign: 'center', align: 'center' });
      }
    }
  });
  return { columns, aggregation };
};