// src/redux/slices/ReplenishmentPlannerCart.js
import { createSlice } from '@reduxjs/toolkit';
import { API_LIMIT, DATA_FETCH_MSG, GET_LOCATION_DETAILS, REPLENISH_PLANNER_OLD_API } from 'pages/common/Constants';
import { requestAPI, getClientId } from "pages/common/Utils";
import { populateColumns } from "store/common/replenishmentPopulate";

const initialState = {
    location: '',
    locationData: [],
    locationValue: '',
    tableData: [],
    columnData: [],
    loading: false,
    loading: false,
    goBtn: false,
    aggregationModel: {},
    openAlert: false,
    alertMessage: '',
    severity: '',
    daysInvPlanned: 30,
    pastSalesDRR: 30,   
};

const ReplenishmentPlannerCart = createSlice({
    name: 'replenishmentPlannerCart',
    initialState,
    reducers: {
        setLoading(state, action) {
            state.loading = action.payload;
        },
        setError(state, action) {
            state.error = action.payload;
        },
        setGoBtn(state, action) {
            state.goBtn = action.payload;
        },
        setLocation(state, action) {
            state.location = action.payload;
        },
        setIsLoading(state, action) {
            state.isLoading = action.payload;
        },
        setLocationData(state, action) {
            state.locationData = action.payload;
        },
        setTableData(state, action) {
            state.tableData = action.payload;
        },
        setColumnData(state, action) {
            state.columnData = action.payload;
        },
        setAggregationModel(state, action) {
            state.aggregationModel = action.payload;
        },
        setDaysInvPlanned(state, action) {
            state.daysInvPlanned = action.payload;
        },
        setPastSalesDRR(state, action) {
            state.pastSalesDRR = action.payload;
        },
        setOpenAlert(state, action) {
            state.openAlert = action.payload.openAlert;
            state.alertMessage = action.payload.alertMessage;
            state.severity = action.payload.severity;
        },
    },
});

export const {
    setLoading,
    setIsLoading,
    setGoBtn,
    setLocation,
    setLocationData,
    setTableData,
    setColumnData,
    setOpenAlert,
    setAggregationModel,
    setDaysInvPlanned,  
    setPastSalesDRR,    
    setError
} = ReplenishmentPlannerCart.actions;

export default ReplenishmentPlannerCart.reducer;



export const fetchLocationDetails = () => async (dispatch) => {
   dispatch(setGoBtn(true))
    try {
      const response = await requestAPI(GET_LOCATION_DETAILS+'/'+getClientId(),'GET','','')
      const locationValue = response.data.find((x) => x.attribute === "All")?.attribute || response.data[0]?.attribute ;
      dispatch(setLocationData(response.data));
      dispatch(setLocation(locationValue));
      dispatch(setGoBtn(false))
  
    } catch (error) {
      dispatch(setError("Failed to fetch location details"));
    }
  };
  
  

export const fetchSkuPerformanceData = (days, locationValue, pastSalesDRR) => async (dispatch) => {
    dispatch(setGoBtn(true));
    dispatch(setIsLoading(true));
    dispatch(setLoading(true));
    let reqURL = REPLENISH_PLANNER_OLD_API + "/" + getClientId() + "?days=" + days + "&psalesdrr=" + pastSalesDRR + "&loc=" + locationValue
    try {

        const response1 = await requestAPI(reqURL + "&limit=" + API_LIMIT, 'GET', '', '')
        dispatch(setTableData(response1.data));
        const { columns: c1, aggregation: agg1 } = populateColumns(response1.data);
        dispatch(setColumnData(c1));
        dispatch(setAggregationModel(agg1));
        dispatch(setIsLoading(false));
        dispatch(setLoading(false));

        const response2 = await requestAPI(reqURL, 'GET', '', '')
        dispatch(setTableData(response2.data));
        const { columns: c2, aggregation: agg2 } = populateColumns(response2.data);
        dispatch(setColumnData(c2));
        dispatch(setAggregationModel(agg2));
        dispatch(setGoBtn(false));
        dispatch(setOpenAlert({
            open: true,
            message: DATA_FETCH_MSG,
            severity: "success"
        }));

    } catch (error) {
        console.log("Failed to fetch Replenishment Planner data : ", error);
        dispatch(setError("Failed to fetch Replenishment Planner data"));
    }
};